import React, { useState, useEffect } from 'react'
import zunoLabsLogo from '../assets/images/zudo-labs.png'
import skypeLogo from '../assets/images/skypeLogo.png'
import mailLogo from '../assets/images/mailLogo.png'
import twitterLogo from '../assets/images/Twitter.svg'
import facebookLogo from '../assets/images/Facebook.png'
import instaLogo from '../assets/images/Instagram.png'
import youtubeLogo from '../assets/images/youtube.svg'
import appStore from '../assets/images/appstoreImg.png'
import playStore from '../assets/images/gplayImg.png'
import footerimage from '../assets/images/footer-image.png'
import { Link } from 'react-router-dom';
import { WOW } from "wowjs";
import "wowjs/css/libs/animate.css";
import { useNavigate, useLocation } from 'react-router-dom';
function Footer() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let newErrors = {};

        // Name validation
        if (!formData.name.trim()) {
            newErrors.name = "Name is required";
        } else if (formData.name.trim().length < 2) {
            newErrors.name = "Name must be at least 2 characters long";
        }

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email.trim()) {
            newErrors.email = "Email is required";
        } else if (!emailRegex.test(formData.email)) {
            newErrors.email = "Please enter a valid email address";
        }

        // Message validation (optional)
        if (formData.message.trim() && formData.message.trim().length < 10) {
            newErrors.message = "Message should be at least 10 characters long";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        // Clear error when user starts typing
        if (errors[name]) {
            setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log('Form submitted:', formData);
            // Here you would typically send the form data to your backend
            // Reset form after successful submission
            setFormData({ name: '', email: '', message: '' });
        } else {
            console.log('Form has errors');
        }
    };
    useEffect(() => {
        // Initialize WOW.js
        new WOW().init();
    }, []);

    const navigate = useNavigate();
    const location = useLocation();

    const handleScrollTo = (id) => {
        if (location.pathname !== '/') {
            navigate('/');
            // We need to wait for the navigation to complete before scrolling
            setTimeout(() => {
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        } else {
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
    const handleNavigation = (path) => {
        navigate(path);
    };
    return (
        <>
            <div className="contact-form-container wow fadeIn" id='contact'>
                <div className="contact-form-content">
                    {/* <div className="image-container">
                        <img src={footerimage} alt="Footer illustration" />
                    </div> */}
                    <div className="form-container">
                        <h2>CONTACT US</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="form-field">
                                <label htmlFor="name">Name <span className='red'>*</span></label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                />
                                {errors.name && <span className="error">{errors.name}</span>}
                            </div>
                            <div className="form-field">
                                <label htmlFor="email">Email Address <span className='red'>*</span></label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                />
                                {errors.email && <span className="error">{errors.email}</span>}
                            </div>
                            <div className="form-field">
                                <label htmlFor="message">Message</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleInputChange}
                                    rows={4}
                                />
                                {errors.message && <span className="error">{errors.message}</span>}
                            </div>
                            <button type="submit">
                                SUBMIT
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <footer className='footer-section'>
                <div className='footer-content container wow fadeIn'>
                    <div className='footer-logo'>
                        <img src={zunoLabsLogo} alt='Zuno Labs Logo' />
                    </div>
                    <div className='footer-links wow fadeIn'>
                        <ul>
                            <li><Link onClick={() => handleScrollTo('/privacy-policy')} to='/privacy-policy' >Privacy Policy</Link></li>
                            <li><Link onClick={() => handleScrollTo('/disclaimer')} to='/disclaimer'>Disclaimer</Link></li>
                            <li><Link onClick={() => handleScrollTo('games')} to='/' >Game</Link></li>
                            <li><Link onClick={() => handleScrollTo('about')} to='/' >About Us</Link></li>
                        </ul>
                    </div>
                    <div className='footer-contact wow fadeIn'>
                        <h2 className='link-header'>Start Chat With US</h2>
                        <div className='contact-info'>
                            <a href='mailto:hiten@yudiz.com'>
                                <img src={mailLogo} alt='mail' />
                                hiten@yudiz.com
                            </a>
                            <a href='skype:yudiz.hiten'>
                                <img src={skypeLogo} alt='skype' />
                                Skype: yudiz.hiten
                            </a>
                        </div>
                        <div className='social-media'>
                            <a href='https://x.com/zudolabs' target='_blank'><img src={twitterLogo} alt="Twitter" /></a>
                            <a href='https://www.facebook.com/zudolabs' target='_blank'><img src={facebookLogo} alt="Facebook" /></a>
                            <a href='https://www.instagram.com/zudolabs' target='_blank'><img src={instaLogo} alt="Instagram" /></a>
                            <a href='https://www.youtube.com/@ZudoLabs' target='_blank'><img src={youtubeLogo} alt="youtubeLogo" /></a>
                        </div>
                    </div>
                    <div className='footer-download wow fadeIn'>
                        <h2 className='link-header'>Available on</h2>
                        <div className='download-links'>
                            <a href='https://play.google.com/store/apps/dev?id=5944425776374284534' target='_blank'><img src={playStore} alt="Google Play Store" /></a>
                            {/* <a href='#'><img src={appStore} alt="Apple App Store" /></a> */}
                        </div>
                    </div>
                </div>
                <div className='copyright-sec container'>
                    <p>© Copyright 2024, All Rights Reserved by Zudo Labs</p>
                </div>
            </footer>
        </>

    )
}

export default Footer