import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Header />
      <div className="privacy-policy-container">
        <h1>Privacy Policy</h1>
        <p>At Zudo Labs, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we handle the information we collect when you visit our website [zudolabs.com] and use our games, including our first meta-project, Sports Verse, in the VR category.</p>

        <h2>1. Information We Do Not Collect</h2>
        <p>Zudo Labs does not collect any personal information directly from users. This means we do not gather, store, or process any personal data such as names, email addresses, phone numbers, physical addresses, payment information, or usage data.</p>

        <h2>2. Third-Party Plugins and Services</h2>
        <p>Our website and games may include third-party plugins or services that collect information. These third-party services are not operated by Zudo Labs, and we do not control their privacy practices. Examples include:</p>
        <ul>
          <li>Oculus Store Services: May collect personal information such as account details, purchase history, and usage data.</li>
          <li>Analytics Services: May collect data to help us understand how our products are used and improve the user experience.</li>
          <li>Advertising Services: May collect data to serve targeted advertisements and measure their effectiveness.</li>
        </ul>
        <p>We encourage you to review the privacy policies of any third-party services you interact with to understand their data collection and usage practices.</p>

        <h2>3. Security</h2>
        <p>While we do not collect personal information, we are committed to maintaining a secure environment for our users. We design our website and games to operate securely and protect against unauthorized access, alteration, disclosure, or destruction.</p>

        <h2>4. Third-Party Links</h2>
        <p>Our website and services may contain links to third-party websites. Zudo Labs is not responsible for the privacy practices or content of those sites. We encourage you to review the privacy policies of any third-party websites you visit.</p>

        <h2>5. Children's Privacy</h2>
        <p>Our services are not directed to individuals under the age of 13. We do not knowingly collect personal information from children under 13. If you believe that a child under 13 may have provided personal information through third-party services, please contact us so we can take appropriate action.</p>

        <h2>6. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website and updating the effective date. We encourage you to review this Privacy Policy periodically for any changes.</p>

        <div class="contact-info">
          <h2>7. Contact Us</h2>
          <p>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:</p>
          <p class="contact-details">
            Zudo Labs<br />
            Email: <a href="mailto:Hiten@yudiz.com">Hiten@yudiz.com</a>
          </p>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default PrivacyPolicy