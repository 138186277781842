import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'

function Disclaimer() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Header />
            <div className="privacy-policy-container">
                <h1>Disclaimer</h1>
                <p>“This website may contain other proprietary notices and copyright information, the terms of which must be observed and followed.</p>
                <p>Information on this website may contain technical inaccuracies or typographical errors.</p>
                <p>Information may be changed or updated without notice. Zudo Labs reserves the right to make improvements and/or changes in the services and/or programs described on this website at any time without notice.</p>
                <p>While every effort has been made to offer current and accurate information, errors can occur. Zudo Labs does not warrant that this website, various services provided through this website and any information, software or other material downloaded from this website, will be uninterrupted, error-free, or free of viruses or other harmful components. Furthermore, this site may contain reference to certain laws and regulations. Laws and regulations will change over time and should be interpreted only in light of particular circumstances. Information provided on this website is provided “as is”, with no guarantees of completeness, accuracy or timeliness and without warranties of any kind, express or implied, including but not limited to implied warranties of merchantability and fitness for any particular purpose. Zudo Labs disclaims any responsibility for the accuracy of any data or statistics mentioned on the website. The platform presents the same “AS-IS” without any warranties. Zudo Labs expressly excludes all liability, to the extent permitted by the law, for any loss or damage that may arise directly or indirectly from or in connection with this website.</p>
                <p>The information presented on this website should not be construed as the last word and commitment of the company. You should consult with the authorized representative of Zudo Labs before making any decision.</p>
                <p>Certain links may be provided on this website, which will lead to websites maintained by individuals or organizations over which Zudo Labs has no control. Zudo Labs makes no representations and provides no warranties regarding the accuracy or any other aspect of the information located on such websites.</p>
                <p>Any quotation received from the website is only a tentative and approximate quote and shall not in any way be final and binding upon the company except only in case it is signed and stamped by any authorized representative of Zudo Labs for that specific purpose.</p>
                <p>The responsibility for any opinions, advice, statements, or other information contained in any articles or texts posted or transmitted on this website resides solely with the author and may not necessarily reflect opinions and policies of Zudo Labs itself.</p>
                <p>Zudo Labs adheres to a standard system and makes no distinctions based on race, caste, creed or faith. Therefore, it is not advised or appropriate to consider any of the claims which will be based on such distinction at any point of time. This disclaimer is governed by and interpreted in accordance with the laws of India, without regard to the choice or conflicts of law provisions of any jurisdiction. The Firms/site visitor agrees that in the event of any dispute arising in relation to this Disclaimer or any dispute arising in relation to the website, whether in contract or tort or otherwise, to submit to the jurisdiction of the courts located solely and exclusively at Ahmedabad (Gujarat) (India) for the resolution of all such disputes.</p>
                <p>IN NO EVENT WILL Zudo Labs BE LIABLE TO ANY PARTY FOR ANY DIRECT, INDIRECT, SPECIAL, OR OTHER CONSEQUENTIAL DAMAGES ARISING FROM THE USE OF THIS WEBSITE OR ANY OTHER HYPERLINKED WEBSITE, INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF PROGRAMS, OR OTHER DATA ON YOUR INFORMATION HANDLING SYSTEM OR OTHERWISE, EVEN IF WE ARE EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.”</p>
            </div>
            <Footer />
        </>
    )
}

export default Disclaimer