import React from 'react'
import Slider from "react-slick";
import basketball from '../assets/images/basketball.png'
import sportverse from '../assets/images/sport-verse.png'
import tennisstrike from '../assets/images/tennis-strike.png'
import pickleball from '../assets/images/pickleball.png'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <FaArrowLeft className="slick-prev-icon" />
        </div>
    );
};

export const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <FaArrowRight className="slick-next-icon" />
        </div>
    );
};

function GameSlider() {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        responsive: [

            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className='games-slider'>
            <Slider {...settings}>

                <div>
                    <div>
                        {/* <img src={slide2}></img> */}
                        <div className='game-slide'>
                            <img src={sportverse} />
                            <h3>Sportsverse</h3>
                            <Link to='https://www.meta.com/experiences/7927751617307645/?require_login=true&utm_source=developer.oculus.com&utm_medium=oculusredirect' target='_blank'></Link>
                        </div>
                    </div>
                </div>
                <div >
                    <div>
                        {/* <img src={slide1}></img> */}
                        <div className='game-slide'>
                            <img src={pickleball} />
                            <h3>Pickleball Stars</h3>
                            <span>Upcoming</span>
                            {/* <Link to='https://play.google.com/store/apps/details?id=com.zudolabs.pickleballstars' target='_blank'></Link> */}
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className='game-slide'>

                            <img src={tennisstrike} />
                            <h3>Tennis Strike</h3>
                            <span>Upcoming</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className='game-slide'>
                            <img src={basketball} />
                            <h3>Basketball</h3>
                            <span>Upcoming</span>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    )
}

export default GameSlider
