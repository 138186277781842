import React, { useEffect } from "react";
import Header from "../../components/Header";
import bgImg from "../../assets/images/pickelBallBg.png";
import appStore from "../../assets/images/appstoreImg.png";
import playStore from "../../assets/images/gplayImg.png";
import metastore from "../../assets/images/meta-store-icon.png";
import GameSlider from "../../components/GameSlider";
import aboutImg from "../../assets/images/about-img.png";
import { Form } from "reactstrap";
import Footer from "../../components/Footer";
import pickleballbg from "../../assets/images/backGround.png";
import sportversebg from "../../assets/images/sportversebg.jpg";
import picklballimg from "../../assets/images/pickleball-banner.png";
import Slider from "react-slick";
import { WOW } from "wowjs";
import "wowjs/css/libs/animate.css";

function Home() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnFocus: false,
    fade: true,
  };
  useEffect(() => {
    // Initialize WOW.js
    new WOW().init();
  }, []);
  return (
    <>
      <Header />
      {/* <div className='home'> */}
      {/* ---HomeSec---- */}
      <section className="home-container">
        <div className="banner-slider">
          <Slider {...settings}>
            <div>
              <div
                className="homeBg"
                style={{ backgroundImage: `url(${sportversebg})` }}
              >
                <img src={picklballimg} alt="img"></img>
                <div className="container">
                  <div className="social-container">
                    <span>AVAILABLE ON</span>
                    <div className="social-containt">
                      <div className="googlePlay-container">
                        <a
                          href="https://www.meta.com/experiences/7927751617307645/?require_login=true&utm_source=developer.oculus.com&utm_medium=oculusredirect"
                          target="_blank"
                        >
                          <img src={metastore} alt="img"></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                className="homeBg"
                style={{ backgroundImage: `url(${pickleballbg})` }}
              >
                <img src={bgImg} alt="img"></img>
                <div className="container">
                  <div className="social-container">
                    <span>AVAILABLE ON</span>
                    <div className="social-containt">
                      <div className="googlePlay-container">
                        <a
                          href="https://play.google.com/store/apps/dev?id=5944425776374284534"
                          target="_blank"
                        >
                          <img src={playStore} alt="img"></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
      {/* ---games-section---- */}
      <section className="games-section" id="games">
        <div className="inner-content container ">
          <div className="games-header">
            <div className="games-heading wow slideInLeft">
              <span>Games</span>
            </div>
            <div className="seemore-heading wow slideInRight">
              <a
                href="https://play.google.com/store/apps/dev?id=5944425776374284534"
                target="_blank"
              >
                <span>see more</span>
              </a>
            </div>
          </div>
          <div className="games-slider wow fadeIn">
            <GameSlider />
          </div>
        </div>
      </section>
      {/* ---AboutSec---- */}
      <section className="about-section" id="about">
        <div className="container">
          <div className="about-content">
            <div className="about-text wow slideInLeft">
              <h1 className="about-title">
                Discover Your Next Favorite Game at
                <span className="zudolabs-text">ZudoLabs</span>
              </h1>
              <p>
                Welcome to ZudoLabs, your ultimate destination for a diverse
                gaming experience! At ZudoLabs, we are passionate about bringing
                the joy of games to everyone, offering a variety of exciting and
                engaging games in one app. Whether you're a fan of tennis,
                pickleball, ludo, or other fun games, we've got something for
                you.
              </p>
              <p>
                Our mission is to create a seamless and enjoyable gaming
                experience, providing high-quality games that are easy to play
                and hard to put down.
              </p>
              {/* <button className='see-more-btn'>SEE MORE</button> */}
            </div>
            <div className="about-image wow slideInRight">
              <img src={aboutImg} alt="ZudoLabs Gaming" />
            </div>
          </div>
        </div>
      </section>
      {/* </div> */}
      {/* ---FooterSec---- */}
      <Footer />
    </>
  );
}

export default Home;
