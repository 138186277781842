import React, { useState } from 'react';
import zunoLabsLogo from '../assets/images/zudo-labs.png'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Header(args) {
    // const [isOpen, setIsOpen] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const handleScrollTo = (id) => {
        if (location.pathname !== '/') {
            navigate('/');
            // We need to wait for the navigation to complete before scrolling
            setTimeout(() => {
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        } else {
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
    const handleNavigation = (path) => {
        navigate(path);
    };

    const toggle = () => setIsOpen(!isOpen);
    const [isOpen, setIsOpen] = React.useState(false)

    return (
        <header className="header">
            <Navbar {...args} expand={"md"} className={isOpen === true ? 'toggle-btn-open' : ''} >
                <div className='container'>
                    <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} >
                        <span></span>
                        <span></span>
                        <span></span>
                    </NavbarToggler>
                    <NavbarBrand href="/"><img src={zunoLabsLogo} alt="Zuno Labs" className="logo" height={"109"} width={"167"} /></NavbarBrand>
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="me-auto" navbar>
                            <div className="nav-left">
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>
                                        Games
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem><Link to='https://www.meta.com/experiences/7927751617307645/?require_login=true&utm_source=developer.oculus.com&utm_medium=oculusredirect' target='_blank'>Sportsverse</Link></DropdownItem>
                                        {/* <DropdownItem><Link to='https://play.google.com/store/apps/details?id=com.zudolabs.pickleballstars' target='_blank'>Pickleball Stars</Link></DropdownItem> */}
                                        <DropdownItem>Pickleball Stars</DropdownItem>
                                        <DropdownItem>Tennis Strike</DropdownItem>
                                        <DropdownItem>Basketball</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>

                            <div className="nav-right">
                                <NavItem>
                                    <NavLink onClick={() => handleScrollTo('about')} >About</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => handleScrollTo('contact')}   >Contact</NavLink>
                                </NavItem>
                            </div>

                        </Nav>
                    </Collapse>
                </div>
            </Navbar>
        </header>
    );
}

export default Header;